import React from 'react';
import './Plpd.css'

class Plpd extends React.Component {

    render() {
        return (
            <div className="frame plpd1">
                <div className="square">
                    <div className="container bottom-container">
                        <div className="back side"></div>
                        <div className="left side"></div>
                        <div className="right side"></div>
                        <div className="top side"><div className="pseudoTop side"></div></div>
                        <div className="bottom side"></div>
                        <div className="front side"><div className="pseudoFront side"></div></div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Plpd;