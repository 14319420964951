import React from 'react';
import Faq from './Faq';
import Cubes from './Cubes';
import Cubes2 from './Cubes2';
import Plpd from './Plpd';
import Plpd2 from './Plpd2';

import { BrowserRouter as Router, Route } from "react-router-dom";


class App extends React.Component {

    render() {
        return (
          <Router>
            <Route exact path="/faq/" render={() => <Faq class='faq1' />} />
            <Route exact path="/faq2/" render={() => <Faq class='faq2' />} />
            <Route exact path="/cubes/" component={Cubes} />
            <Route exact path="/cubes2/" component={Cubes2} />
            <Route exact path="/plpd/" component={Plpd} />
            <Route exact path="/plpd2/" render={() => <Plpd2 class='plpd2' />} />
            <Route exact path="/plpd3/" render={() => <Plpd2 class='plpd3' />} />
            <Route exact path="/plpd4/" render={() => <Plpd2 class='plpd4' />} />
            <Route exact path="/plpd5/" render={() => <Plpd2 class='plpd5' />} />
            <Route exact path="/plpd6/" render={() => <Plpd2 class='plpd6' />} />
            <Route exact path="/plpd7/" render={() => <Plpd2 class='plpd7' />} />
            <Route exact path="/plpd8/" render={() => <Plpd2 class='plpd8' />} />
        </Router>
        )
    }
}

export default App;