import React from 'react';
import './Cubes.css'

class Cubes extends React.Component {

    render() {
        return (
            <div className="frame cubes2">
                <div className="square">
                    <div className="scontainer scontainer2">
                        <div className="container left-container">
                            <div className="back side"><div className="pseudoBack side"></div></div>
                            <div className="left side"></div>
                            <div className="right side"><div className="pseudoRight side"></div></div>
                            <div className="top side"></div>
                            <div className="bottom side"></div>
                            <div className="front side"><div className="pseudoFront side"></div></div>
                        </div>
                    </div>
                    <div className="scontainer">
                        <div className="container right-container">
                            <div className="back side"></div>
                            <div className="left side"></div>
                            <div className="right side"><div className="pseudoRight side"></div></div>
                            <div className="top side"><div className="pseudoTop side"></div></div>
                            <div className="bottom side"></div>
                            <div className="front side"><div className="pseudoFront side"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Cubes;