const testData = {
    body:  [
        ["What will be the format of The Terraforming 2022 program?", "The team of The Terraforming is closely monitoring the epidemiological situation globally and the corresponding COVID-19 travel restrictions and regulations. As much as feasible we hope to prioritise offline collaboration between the participants in the coming year. The 2022 program will have a hybrid format. It will begin with an offline field-trip when the group will have a chance to meet in person, followed by 2 months of online seminars, and will end with an extensive design-push phase, which will take place offline in 1 location for the period of approximately 3 months.<br><br> The program would require a full commitment from the participants for the entire period of 5 months. We will be making additional announcements on the format specifics in the coming months.<br><br> We are committed to develop the best combination of online and in-person collaborations for the entire cohort that would provide a deep dive into The Terraforming research agenda and a unique and exciting experience for the researchers."],
        ["What is the tuition fee for the program and does the Institute provide a stipend?", "There are no tuition fees for The Terraforming program, it is free for all participants. In addition, the researchers receive a monthly stipend of 39,200 rubles."],
        ["Is the program full-time?", "Yes, the program requires a full-time commitment. The sessions are scheduled daily for the entire cohort, sometimes including additional seminars on weekends."],
        ["What kind of degree will I receive upon completing the program?", "Participants receive certificates issued by the Institute confirming completion of the program. Strelka Institute does not award state-recognized degrees."],
        ["Can I apply if I am not an architect or designer?", "The program is multidisciplinary and we accept applications from participants from any professional background (architecture, urbanism, filmmaking, design, journalism, digital media, philosophy, social studies, political science and other fields)."],
        ["Can I apply to Strelka if my English is not very good?", "The working language of The Terraforming program is English; all seminars and workshops run in English, so fluency in the English language is essential. As part of the application all non-native speakers will be asked to confirm their English language proficiency (by submitting a language proficiency certificate or information about previous work or education experience during which English was the language of instruction / communication)."],
        ["How strict is the age requirement?", "If you are slightly younger/older than the stated age requirement, but you have a bachelors and/or masters degree and no less than 2 years of work experience we encourage you to apply. All applications are reviewed based on merit and no application will be rejected based on the applicant’s age."],
        ["Are there any significant program related costs that I will need to cover?", "Apart from personal living expenses, there are no costs related to the program for the researchers to cover. Travel expenses (such as flights and accommodation) for the field trips are covered by Strelka, while food, transportation and other personal expenses are covered by the researchers."],
        ["Where do I find more information about the program?", `Please visit <a href='https://theterraforming.strelka.com/' target='_blank' rel="noopener noreferrer">theterraforming.strelka.com</a> for all program related information. We encourage you to <a href='http://eepurl.com/g8CDgD' target='_blank' rel="noopener noreferrer">subscribe to our newsletter</a> to stay tuned for updates with regards to the program. If you have any specific questions, please contact the Student Coordinator at <a href='mailto:apply@strelka.com' target='_blank' rel="noopener noreferrer">apply@strelka.com</a>.`],
        ["Are there any opportunities for part-time participation in The Terraforming program? Can I join specific parts of the program?", `The 2022 program has a single track that implies participation in the entire program throughout the five months period. We will be releasing materials in various formats that you can follow to engage with the content that is developed in The Terraforming. New publishing opportunities (similar to <a href='https://strelkamag.com/en?topic=the-revenge-of-the-real' target='_blank' rel="noopener noreferrer">The Revenge of The Real</a> and <a href='https://strelkamag.com/en?topic=for-planetary-governance' target='_blank' rel="noopener noreferrer">For Planetary Governance</a> on Strelka Mag) will also take place in 2022 and will be announced via a separate call for applications.`],
        ["Can I join the program next year? ", "The current application call is for the third and final cohort of The Terraforming program. New education opportunities for the academic year 2022-2023 will be announced in the fall of 2022 on the official communication channels of Strelka Institute."],
    ]
}

export default testData;