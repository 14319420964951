import React from 'react';
import testData from './data';
import './Faq.css'
// import ReactDOM from 'react-dom';

import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MuiTypography from '@material-ui/core/Typography';

import styled from 'styled-components';

// import Typography from '@material-ui/core/Typography';

/** @jsx jsx */
import { css, jsx } from '@emotion/core';


const ExpansionPanel = withStyles({
  root: {
    color: props => props.faq === 'faq2' ? 'white' : 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5045vw',
    '@media (min-width: 3601px)': {
      fontSize: '46px',
    },
    '@media (max-width: 768px)': {
      fontSize: '9.75px',
    },
    lineHeight: '1.3em',
    borderRadius: '0px',
    border: 'none',
    // borderTop: '1px solid #AAAAAA',
    borderBottom: props => props.faq === 'faq2' ? '2px solid #930A0A' : '2px solid #AAAAAA',
    boxShadow: 'none',
    minHeight: '1.3em',
    // marginTop: '-1px',
    // transition: 'height 300ms',
    // '&$expanded': {
    //   transition: 'height 300ms',
    // },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    padding: '0.65em 0 0.65em',
    minHeight: '1.3em',
    position: 'relative',
    '&:focus': {
      background: 'inherit',
    },
    '&$expanded': {
      margin: 0,
      // height: '48px',
      padding: '0.65em 0 0.65em',
      minHeight: '1.3em',
    },
    '&:before': {
      position: 'absolute',
      height: '0px',
      width: '100%',
      content: '""',
      zIndex: -1,
      background: props => props.faq === 'faq2' ? 'linear-gradient(to bottom, #930A0A, transparent 50%)' : 'linear-gradient(to bottom, #AAAAAA, transparent 50%)',
      transition: 'height 500ms',
      top: '-1px',
      left: 0,
    },
    '&$expanded:before': {
      // background: 'linear-gradient(to bottom, #AAAAAA, transparent)',
      height: '100px',
      // transition: 'background 500ms, height 500ms',
    },
  },
  content: {
    margin: 0,
    '&$expanded': {
      margin: 0,
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);


const ExpansionPanelDetails = withStyles({
  root: {
    padding: '0.65em 0 0.65em',
    // opacity: 0,
    transition: 'visibility 500ms',

    '&$hidden': {
      padding: '0.65em 0 0.65em',
      // opacity: 1,
    }
  },
  expanded: {},
})(MuiExpansionPanelDetails);


const Typography = withStyles({
  root: {
    fontSize: '1.5045vw',
    '@media (min-width: 3601px)': {
      fontSize: '46px',
    },
    '@media (max-width: 768px)': {
      fontSize: '9.75px',
    },
    lineHeight: '1.3em',
    fontFamily: 'Suiss'
  },
  expanded: {
    fontFamily: 'SuissItalic'  
  },
})(MuiTypography);


const TextComp = styled.p`
  a {
    color: inherit
  }
`

const Text = (props) => (
  <TextComp
      dangerouslySetInnerHTML={
          props.html ? { __html: props.children } : undefined
      }
  >
      {props.html ? undefined : props.children}
  </TextComp>
);

class Faq extends React.Component {
    constructor(props) {
        super(props);
        let that = this;
        this.myRef = React.createRef();
        this.state = {collapsedElement: '', expanded: false};

        this.handleChange = panel => (event, isExpanded) => {
            that.setState({expanded: (isExpanded ? panel : false)});
        };
    }

    // handleClickedElement(target) {
    //     this.setState(state => ({collapsedElement: 'gfewrw'}));
    //     console.log(this.state);
    // }

    render() {
        const that = this;

        const styledPanel = {
          backgroundColor: 'transparent'
        }

        const transitionDuration =  {timeout: 500}
        // const classes = useStyles();

        const leftPanels = testData.body.map((prop, index) => {

          if (index <= 6)
            return (
            <li
              css={css`
                list-style: none;
                margin-top: -1px;
              `}>
              <ExpansionPanel faq={this.props.class} square style={styledPanel} TransitionProps={transitionDuration} expanded={that.state.expanded === ('panel' + index)} onChange={that.handleChange('panel' + index)}>
                <ExpansionPanelSummary
                    faq={this.props.class}
                    aria-controls={"panel" + index + "bh-content"}
                    id={"panel" + index + "bh-header"}>
                  <Typography>{prop[0]}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography>
                    <Text html>
                      {prop[1]}
                    </Text>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel></li>)
            else
                return null
        })


        const rightPanels = testData.body.map((prop, index) => {

          if (index > 6)
            return (
            <li
              css={css`
                list-style: none;
                margin-top: -1px;
              `}>
              <ExpansionPanel faq={this.props.class} square style={styledPanel} TransitionProps={transitionDuration} expanded={that.state.expanded === ('panel' + index)} onChange={that.handleChange('panel' + index)}>
                <ExpansionPanelSummary
                    faq={this.props.class}
                    aria-controls={"panel" + index + "bh-content"}
                    id={"panel" + index + "bh-header"}>
                  <Typography>{prop[0]}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Typography expanded={true}>
                    <Text html>
                      {prop[1]}
                    </Text>
                  </Typography>
                </ExpansionPanelDetails>
              </ExpansionPanel></li>)
            else
                return null
        })


        return (
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin: 0;
            `}>
            <ul
                css={css`
                    margin: 0 40px 0 0;
                    padding: 0;
                    width: 50%;
                    border-top: ${this.props.class === 'faq2' ? '2px solid #930A0A' : '2px solid #AAAAAA'};
                `}
                ref={this.myRef} >
                {leftPanels}
            </ul>
            <ul
                css={css`
                    margin: 0;
                    padding: 0;
                    width: 50%;
                    border-top: ${this.props.class === 'faq2' ? '2px solid #930A0A' : '2px solid #AAAAAA'};
                `}
                ref={this.myRef} >
                {rightPanels}

            </ul>
          </div>
        )
    }
}

// const domContainer = document.querySelector('#faq');
// ReactDOM.render(e(LikeButton), domContainer);

export default Faq;
