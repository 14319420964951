import React from 'react';
import './Plpd2.css'

class Plpd extends React.Component {

    render() {
        return (
            <div className={"frame plpd " + this.props.class}>
                <div className="square">
                    <div className="container bottom-container">
                        <div className="top side"></div>
                        <div className="front side"></div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Plpd;